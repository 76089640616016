import React from 'react';
import './world.css'



const World = () => {


    return <div id="world">
        <div className='row'>
            <div className='col app display-1'><i className="bi bi-bookmark-check"></i>  Vision</div>
        </div>
        <div className='row'>
            <div className='col-md-12 col-sm-12'>

                <video controls width="100%">
                    <source src="https://d3rvoq7vinvk8v.cloudfront.net/out/v1/3c24cabe18c743afb1bf0e39d109e99f/bf97f16f8cb5498490024d3b9ba4cbb9/e72659ab11d240d28676ba0ba2ef0db4/index.m3u8"
                         />
                </video>

            </div>
        </div>

    </div>;
};

export default World;
