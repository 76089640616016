import React from 'react'
import About from '../components/Abouts/About'
import Application from '../components/Application/Application'
import Footer from '../components/footer/Footer'
import Header from '../components/Header/Header'
import Navbar from '../components/Navbar/Navbar'
import World from '../components/world/World'

const Home = () => {
    return (
        <div style={{overflow:"hidden"}}>
            <Navbar/>
            <Header/>
           <div className='container'>
           <Application></Application>
           <World></World>
           <About></About>
           </div>
           <Footer></Footer>
        </div>
    )
}

export default Home

