import React from 'react';
import './footer.css'

const Footer = () => {
    return < div className='footer'>

    <div className='container mb-4 mt-4 pt-4'>
        <div className='row'>
            <div className='col-sm-12 col-md-4 d-flex ' >
                <ul>
                    <li style={{cursor: 'pointer'}}>   <a  style={{fontSize:"1rem",color: 'white',textDecoration:"none"}} aria-current="page" href="#app"> <i className="bi bi-app"></i> Baoba App</a> </li>
                    <li style={{cursor: 'pointer'}} >  <a  style={{fontSize:"1rem",color: 'white',textDecoration:"none"}} aria-current="page" href="#world">   <i className="bi bi-bookmark-check"></i> Vision </a></li>
                    <li style={{cursor: 'pointer'}}><a  style={{fontSize:"1rem",color: 'white',textDecoration:"none"}} aria-current="page" href="#about">  <i className="bi bi-building"></i> About </a></li>
                </ul>
            </div>
            <div className='col-sm-12 col-md-4 d-flex  justify-content-md-center '>
                <ul>
                   
                </ul>
            </div>
            <div className='col-sm-12 col-md-4 d-flex justify-content-md-end ' >
                <ul>
                    <li><i className="bi bi-envelope"></i> contact@thebaoba-group.com</li>
                
                </ul>
            </div>

        </div>

    </div>
        <div className="row">
            <div className="col-12">
                <div className=' d-flex justify-content-center align-items-center mb-4'>
                    Copyright © 2024 The Baoba Groug, Inc.. All rights reserved.
                </div>
            </div>
        </div>

    </div>

};

export default Footer;
