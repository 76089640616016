import React, { useEffect, useState } from 'react';
import './Application.css'
import image from '../../common/images/1.png'
import image2 from '../../common/images/2.png'
import image3 from '../../common/images/3.png'
import image4 from '../../common/images/4.png'

const Application = () => {

  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => setShow(!show), 25000)
    console.log("userfectc-----------------",show)
  },[])

  const _handleShow = (params:boolean) => setShow(params)



  return <div className='container' id="app">
    <div className='row mt-4'>
      <div className='col app display-1'><i className="bi bi-app"></i> Baoba App</div>
    </div>
    <div style={show ? { display: "none" } : {}}>
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <img src={image} width="100%" alt='application' />
        </div>
        <div className='col-md-6 col-sm-12 d-flex justify-content-start align-items-center'>
          <div className='center-text  '>
            <h1 className='num display-1'> 01</h1>
            <h2 className='title'>Well-being</h2>
            <p className='describe'>
              <ul>
                <li>Privacy & secured data are our  priority</li>
                <li>Strengthen mutual respect & positive relationships</li>
                <li>Preservation of mental health</li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <h1 className='num display-1'> 02</h1>
          <h2 className='title'>Market of ideas</h2>
          <p className='describe'>
            <ul>
              <li>Freedom of expression</li>
              <li>Debates of ideas</li>
              <li>Divergence of opinions can be a strength</li>
            </ul>
          </p>
        </div>
        <div className='col-md-6 col-sm-12'>
          <img src={image2} width="100%" alt='application' />
        </div>
      </div>
    </div>

    <div style={!show ? { display: "none" } : {}}>
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <img src={image3} width="100%" alt='application' />
        </div>
        <div className='col-md-6 col-sm-12 d-flex justify-content-start align-items-center'>
          <div className='center-text  '>
            <h1 className='num display-1'> 03</h1>
            <h2 className='title'>Impact the world</h2>
            <p className='describe'>
              <ul>
                <li>Connect to shape the future</li>
                <li>Leverage individuals through the power of networks</li>
                <li>Together, we can make our planet a better place to live</li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 col-sm-12'>

          <h1 className='num display-1'> 04</h1>
          <h2 className='title'>Redistribution</h2>
          <p className='describe'>
            <ul>
              <li>Social collective intelligence leveraging individuals</li>
              <li>Reward through the currency of human values (Oro-baoba)</li>
              <li>Collective enrichment</li>
            </ul>
          </p>

        </div>
        <div className='col-md-6 col-sm-12'>
          <img src={image4} width="100%" alt='application' />
        </div>
      </div>
    </div>

      <div className='row mt-4'>
          <div className='col-12 d-flex justify-content-center align-items-center '> 
          <i onClick={()=>_handleShow(false)} className={show ? "bi bi-circle side" : "bi bi-circle-fill side"  } ></i> 
          <i onClick={()=>_handleShow(true)} className={show ? "bi bi-circle-fill side" : "bi bi-circle side"  }></i>
        </div>
      </div>

  </div>;
};

export default Application;
