import React from 'react'

const Appads = () => {
    return (
        <div>
          google.com, pub-5889522996360436, DIRECT, f08c47fec0942fa0

        </div>
    )
}

export default Appads
